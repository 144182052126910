<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="tag">互联网数据</div>
      <div class="list_box">
        <div class="search-box">
          <div class="module-title">互联网项目列表</div>
        </div>
        <div class="table_box">
          <el-form :inline="true">
            <el-form-item label="区域：">
              <el-cascader
                size="large"
                :options="areaData"
                v-model="selectedOptions"
                @change="areaHandleChange"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="项目名称：">
              <el-input v-model="projectName" placeholder="请输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="竣工日期：">
                <el-date-picker class="date_select"
                    v-model="preDate"
                    @change="dateChange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
         
            <div class="btn_box">
              <el-button
                class="search_btn"
                type="primary"
                size="small"
                @click="searchData()"
                >查询</el-button
              >
              <el-button class="search_btn" size="small" @click="reset"
                >重置</el-button
              >
            </div>
          </el-form>
          <el-table
            ref="table"
            :data="tableData"
            border
            stripe
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            ></el-table-column>
            
            <el-table-column
              prop="projectName"
              label="项目名称"
              width="140"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="source"
              label="数据来源"
              width="100"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.source=='fang'?'房天下':'安居客'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="区域"
              width="160"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.province ? scope.row.province +'-'+ scope.row.city + ( scope.row.county?'-'+ scope.row.county : '' ) : ''
                }}</span>
              </template>
            </el-table-column>
            
            <el-table-column
              prop="propertyCategory"
              label="物业类别"
              width="80"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="constructionCompany"
              label="开发商"
              width="130"
            ></el-table-column>
            <el-table-column
              prop="constructionType"
              label="建筑类型"
            ></el-table-column>
            <el-table-column
              prop="endDate"
              label="竣工时间"
              align="right"
            ></el-table-column>
            <el-table-column
              prop="buildingArea"
              label="建筑面积(m²)"
              align="right"
            ></el-table-column>
            <el-table-column label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="detail(scope.$index, scope.row)"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  @click="contrast(scope.$index, scope.row)"
                  >数据源对比</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              layout="prev,pager,next,total,sizes,jumper"
              :page-sizes="[10, 50, 100]"
              :total="total"
              :current-page="pageNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore, getLocalStore } from "@/util/store";
import { mapMutations } from "vuex";
import { provinceAndCityData, CodeToText, TextToCode } from "element-china-area-data"; // 地址级联选择器
export default {
  name: "index",
  data() {
    return {
      projectName: "",
      company:'',
      address:'',
      areaData: [],
      selectedOptions: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      province:'',
      city:'',
      county:'',
      preDate:[],
      startDate:'',
      endDate:'',
      jsonData:[{
          "id": 9703,
          "projectId": null,
          "province": "山东省",
          "city": "烟台",
          "county": "芝罘",
          "projectName": "开元盛世",
          "projectAlias": null,
          "projectDesc": null,
          "region": "芝罘 幸福",
          "address": "芝罘幸福开元街180号（开元街北首向北100米） ",
          "circleLineAddress": null,
          "projectFeature": null,
          "decorationCondition": null,
          "propertyDesc": "开元盛世普通住宅产权为70年",
          "propertyCategory": "住宅",
          "constructionYear": "2015-03-31",
          "constructionCompany": "烟台顺源房地产有限公司",
          "constructionType": "暂无资料",
          "buildingArea": "222581.2 ㎡",
          "landArea": "123189.2 ㎡",
          "houseCount": "2391户",
          "buildingCount": "18栋",
          "propertyCompany": "烟台顺源房地产有限公司",
          "propertyOfficePhoneNumber": null,
          "propertyOfficeAddress": null,
          "greeningRate": "45%",
          "plotRate": "1.91",
          "propertyCost": "1.85元/㎡·月",
          "propertyCostDesc": null,
          "extraInfo": "物业费1.85元/平米·月，不含电梯费，电梯费0.3元/平米·月",
          "heating": "集中供暖",
          "communicationEquipment": null,
          "security": null,
          "healthService": null,
          "entrance": null,
          "parkingCount": "2500个，车位配比为1：1。租价140元/位·月。",
          "saleStatus": null,
          "saleDate": null,
          "deliverDate": null,
          "saleAddress": null,
          "inqueryPhoneNumber": null,
          "mainHouseStructure": null,
          "transportCondition": null,
          "surroundingFacilities": null,
          "floorType": null,
          "source":'fang',
          "endDate":'2018年',
      }],
      contrastData: {
          "anjuke": {  //安居客
              "id": 16827,
              "projectId": null,
              "projectGuid": null,
              "type": "house",
              "projectUrl": "https://yt.anjuke.com/community/view/966907",
              "province": "山东省",
              "city": "烟台",
              "county": "芝罘",
              "projectName": "开元盛世",
              "source": "anjuke",
              "projectAlias": null,
              "projectDesc": null,
              "projectType": null,
              "region": null,
              "address": "芝罘 - 幸福 -  开元街180号",
              "circleLineAddress": null,
              "projectFeature": null,
              "decorationCondition": null,
              "propertyDesc": null,
              "propertyCategory": null,
              "constructionYear": null,
              "constructionCompany": null,
              "constructionType": null,
              "buildingArea": null,
              "landArea": null,
              "houseCount": null,
              "buildingCount": null,
              "propertyCompany": null,
              "propertyOfficePhoneNumber": null,
              "propertyOfficeAddress": null,
              "greeningRate": null,
              "plotRate": null,
              "propertyCost": null,
              "propertyCostDesc": null,
              "extraInfo": null,
              "heating": null,
              "communicationEquipment": null,
              "security": null,
              "healthService": null,
              "entrance": null,
              "parkingCount": null,
              "saleStatus": null,
              "saleDate": null,
              "deliverDate": null,
              "saleAddress": null,
              "inqueryPhoneNumber": null,
              "mainHouseStructure": null,
              "transportCondition": null,
              "surroundingFacilities": null,
              "floorType": null,
              "isGet": false,
              "urlAbbr": null,
              "belongTo": null,
              "costType": null,
              "parkCost": null,
              "parkManageCost": null,
              "longitude": null,
              "latitude": null,
              "endDate": null,
              "updateTime": 1648188573000,
              "createTime": 1648087270000,
              "systemTime": null
          },
          "fang": { //房天下
              "id": 9703,
              "projectId": null,
              "projectGuid": "2416730667",
              "type": "house",
              "projectUrl": "https://yt.esf.fang.com/loupan/2416730667/housedetail.htm",
              "province": "山东省",
              "city": "烟台",
              "county": "芝罘",
              "projectName": "开元盛世",
              "source": "fang",
              "projectAlias": null,
              "projectDesc": null,
              "projectType": "old",
              "region": "芝罘 幸福",
              "address": "芝罘幸福开元街180号（开元街北首向北100米） ",
              "circleLineAddress": null,
              "projectFeature": null,
              "decorationCondition": null,
              "propertyDesc": "开元盛世普通住宅产权为70年",
              "propertyCategory": "住宅",
              "constructionYear": "2015-03-31",
              "constructionCompany": "烟台顺源房地产有限公司",
              "constructionType": "暂无资料",
              "buildingArea": "222581.2 ㎡",
              "landArea": "123189.2 ㎡",
              "houseCount": "2391户",
              "buildingCount": "18栋",
              "propertyCompany": "烟台顺源房地产有限公司",
              "propertyOfficePhoneNumber": null,
              "propertyOfficeAddress": null,
              "greeningRate": "45%",
              "plotRate": "1.91",
              "propertyCost": "1.85元/㎡·月",
              "propertyCostDesc": null,
              "extraInfo": "物业费1.85元/平米·月，不含电梯费，电梯费0.3元/平米·月",
              "heating": "集中供暖",
              "communicationEquipment": null,
              "security": null,
              "healthService": null,
              "entrance": null,
              "parkingCount": "2500个，车位配比为1：1。租价140元/位·月。",
              "saleStatus": null,
              "saleDate": null,
              "deliverDate": null,
              "saleAddress": null,
              "inqueryPhoneNumber": null,
              "mainHouseStructure": null,
              "transportCondition": null,
              "surroundingFacilities": null,
              "floorType": null,
              "isGet": true,
              "urlAbbr": "/loupan/2416730667.htm",
              "belongTo": null,
              "costType": null,
              "parkCost": null,
              "parkManageCost": null,
              "longitude": null,
              "latitude": null,
              "endDate": null,
              "updateTime": 1647933460000,
              "createTime": 1647415026000,
              "systemTime": null
          }
      }
    };
  },
  created() {
    //对省市区数据进行修改
    this.areaData = provinceAndCityData;
    this.getListData();
  },
  destroyed() {},
  mounted() {},
  methods: {
    ...mapMutations(["addPath","reducePath", "addMenuList", "reduceMenuList"]),
    //选择省市区
    areaHandleChange(value) {
      if(CodeToText[value[0]]){
        this.province = CodeToText[value[0]];
      }
      if(CodeToText[value[1]]){
        this.city = CodeToText[value[1]];
      }
      
    },
    dateChange(val){
          if (val == null){
              this.preDate = [];
              val = [];
          } 
          if(this.preDate.length>0){
              this.startDate = this.preDate[0]
              this.endDate = this.preDate[1]
          }else{
              this.startDate = ''
              this.endDate = ''
          }
      },
    //获取表格数据
    getListData() {
      this.axios({
        method: "post",
        url: "/dms/pageForInternetProject",
        data: {
          province: this.province,
          city: this.city,
          projectName: this.projectName,
          startYear: this.startDate,
          endYear: this.endDate,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;

        // this.tableData = this.jsonData;
        // this.total = this.jsonData.length;

      });
    },
    //查看详情
    detail(index, res) {
      setStore("netData", res);
      this.addMenuList("/netDetail");
      setStore("netfrom", "/netWork");
      setStore("currentMenu", "/netDetail");
      this.$router.push({path: "/netDetail"});
    },
    //数据源对比
    contrast(index, res){
      this.axios({
        method: "post",
        url: "/dms/getSameProject",
        data: {
          province: this.province,
          city: this.city,
          projectName: res.projectName,
        },
      }).then((res) => {
        console.log(res)
        if(!res.data.data.fang){
          this.$message.error('抱歉，暂未找到可对比的项目信息');
          return false;
        }
        this.contrastData = res.data.data;
        setStore("contrastData", this.contrastData);
        this.addMenuList("/contrast");
        setStore("currentMenu", "/contrast");
        this.$router.push({path: "/contrast"});
        

      });
    },
    //切换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getListData();
    },
    //切换页码
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getListData();
    },
    //搜索
    searchData() {
      this.pageNum = 1;
      this.getListData();
    },
    //重置
    reset() {
      this.projectName = '';
      this.company = '';
      this.address = '';
      this.selectedOptions = [];
      this.province = '';
      this.city = '';
      this.preDate = [];
      this.startDate = ''
      this.endDate = ''
    },
  
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.tag {
  color: #646566;
  margin: 16px 0;
}
.list_box {
  width: 100%;
  height: auto;
  background: #ffffff;
}
.search-box {
  height: 54px;
  padding: 10px 24px 0;
  border-bottom: 1px solid #ebebeb;
  overflow: hidden;
  box-sizing: border-box;
  .demo-form-inline{
    float: right;
  }
  .region{
    float: right;
    cursor: pointer;
    color: #185BCE;
    line-height: 32px;
    margin-left: 10px;
  }
  .el-form-item {
    margin-bottom: 11px !important;
  }
  /deep/.el-form-item__content {
    width: 175px;
  }
}
/deep/.el-table__cell{
  padding: 0 !important;
  .cell{
    padding: 5px 8px;
  }
}
.module-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  float: left;
}
.el-cascader {
  line-height: 30px;
}
/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 12px;
}
/deep/.el-form-item__label {
  margin-top: 2px;
  padding-right: 0px !important;
}
/deep/.el-input__icon {
  line-height: 32px;
}
.table_box {
  padding: 16px 24px;
  /deep/.el-form-item__content {
    width: 175px;
  }
}
.btn_box{
    float: right;
    padding-top: 5px;
}
/deep/ th {
  padding: 4px;
}
/deep/ .el-table__cell {
  padding: 5px;
}
</style>